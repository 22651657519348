import type { VfConfig } from '#types/config/components/vf'

export default {
  variants: ['primary', 'secondary', 'primary-inverse', 'secondary-inverse'],
  sizes: ['tiny', 'xs', 'sm', 'md'],
  fallbackVariants: {
    'tertiary': 'secondary',
    'tertiary-inverse': 'secondary-inverse'
  }
} satisfies VfConfig['button']
