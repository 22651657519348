<template>
  <label :id="id" :class="$props.class" :data-test-id="dataTestId || 'base-toggle'">
    <input
      v-model="model"
      class="peer sr-only"
      type="checkbox"
      :value
      v-bind="$attrs"
      @change="$emit('change', ($event.target as HTMLInputElement)?.checked)"
    >
    <slot />
  </label>
</template>

<script lang="ts" setup>
import type { CSSClass } from '#types/common'

defineOptions({ inheritAttrs: false })

defineProps<{
  value?: string
  id?: string
  class?: CSSClass
  dataTestId?: string
}>()

defineEmits<{ change: [value: boolean] }>()

const model = defineModel<boolean | string[]>()
</script>
