import type { RecursivePartial } from '#types/utils'
import type { VfConfig } from '#types/config/components/vf'

export default {
  logoVariant: 'full',
  brandClasses: {
    logo: 'h-5 lg:h-6.5 md:h-6',
    primaryNavigation: 'gap-3',
    totalItems: 'bg-grey-10',
    utilityNavigation: 'gap-4 pb-7 pt-2'
  }
} satisfies RecursivePartial<VfConfig['header']>
