import type { ApiConfig } from '#types/config/api'

export default {
  baseAuthURL: 'https://mcghnwnpzm32pl0cg9sbq9dzhymy.auth.marketingcloudapis.com',
  baseRestURL: 'https://mcghnwnpzm32pl0cg9sbq9dzhymy.rest.marketingcloudapis.com',
  brand: 'TBL',
  region: 'NORA',
  contactKey: {
    CA: 'TBL_ca_customer_care@timberland.com',
    US: 'TBL_us_customer_care@timberland.com'
  },
  address: {
    CA: 'ca_customer_care@timberland.com',
    US: 'us_customer_care@timberland.com'
  },
  personContactId: {
    CA: 'TBL_ca_customer_care@timberland.com',
    US: 'TBL_us_customer_care@timberland.com'
  },
  accountId: {
    CA: 'TBL_ca_customer_care@timberland.com',
    US: 'TBL_us_customer_care@timberland.com'
  },
  eventDefinitionKeys: {
    contactForm: {
      qa: 'APIEvent-f1c88b22-790a-41bf-9168-282987a02036',
      prod: 'APIEvent-8a4636cf-3812-baa5-109a-00eaed950d82'
    }
  }
} satisfies ApiConfig['sfmc']
