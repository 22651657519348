import type { ProductConfig } from '#types/config/components/product'
import type { RecursivePartial } from '#types/utils'

export default {
  showSwatchCount: true,
  brandClasses: {
    swatchCount: 'mono',
    badgeLabel: 'uppercase'
  },
  mediaSize: {
    width: 340,
    height: 340
  },
} satisfies RecursivePartial<ProductConfig['card']>
