import type { RouteRecordRaw } from 'vue-router'
import type { CountryCode, LanguageCode, LocaleCode } from '#types/locale'
import type { I18NBrandConfig } from '#types/i18n'
import type { Translations } from '#brand/types/i18n'
import { mergeDeep } from '#core/utils/object/mergeDeep'

export type Json = string | number | boolean | null | Json[] | { [key: string]: Json }
export interface LocaleContent { [key: string]: Json }
export interface AllLocalesContent { [locale: string]: LocaleContent }

export const slug = (locale: LocaleCode) => `/${locale}`

/**
 *
 * @returns user's language code, by default it will use default locale language code
 * @example 'EN'
 */
export const getLanguageCode = (locale: LocaleCode) => locale.split('-')[0].toUpperCase() as Uppercase<LanguageCode>
/**
 *
 * @returns user's country code, by default it will use default locale country code
 * @example 'US'
 */
export const getCountryCode = (locale: LocaleCode) => locale.split('-')[1].toUpperCase() as Uppercase<CountryCode>

/**
 *
 * @returns Site ID
 * @description retrieved from LD runtime config or brand's prefix and user's country code.
 * @example 'TBL-US'
 */
export const getSiteId = (brandPrefix: string, countryCode: Uppercase<CountryCode>) => {
  if (brandPrefix && countryCode) return `${brandPrefix}-${countryCode}`.toUpperCase()

  throw (new Error('Could not get Site Id'))
}

/**
 * @returns Region name
 * @description retrieved by mapping country code
 * @example 'EMEA' | 'NORA'
 */
export const getRegion = (countryCode: Uppercase<CountryCode>) => {
  switch (countryCode) {
    case 'CA':
    case 'US':
      return 'NORA'
    default:
      return 'EMEA'
  }
}

export const localiseRouteName = (route: RouteRecordRaw, locale: string) =>
  `${route.name?.toString() || route.path.replace(/\//g, '')}_${locale}`

export const isInternal = (url: string) => url.startsWith('/')

export const isLocalised = (url: string, locale: LocaleCode) => url.startsWith(slug(locale))

export const isMedia = (url: string) => url.startsWith('/content/') && url.includes('/media/')

export const removeLocale = (pathName: string) => {
  return pathName.replace(/^\/[a-z]{2}-[a-z]{2}/i, '')
}

export const getFQDN = (i18nConfig: I18NBrandConfig, locale: LocaleCode): I18NBrandConfig['FQDN'] => {
  if (!i18nConfig.domains)
    return i18nConfig.FQDN || ''

  return Object.values(i18nConfig.domains).find((value) => value.locales!.includes(locale))?.FQDN || ''
}

const translationExists = <T extends keyof Translations>(
  translation: Awaited<Translations[T]> | null
): translation is Awaited<Translations[T]> => !!translation

export const readTranslationFiles = async <T extends keyof Translations>(
  type: T,
  brand: string,
  locale: LocaleCode
): Promise<Translations[T]> => {
  const language = getLanguageCode(locale).toLowerCase()

  const translationPaths = [
    ...(language !== 'en' && type === 'seo'
      ? ['assets/server/core/i18n/seo/en.json', `assets/server/${brand}/i18n/seo/en.json`]
      : []),
    `assets/server/core/i18n/${type}/${language}.json`,
    `assets/server/core/i18n/${type}/${locale}.json`,
    `assets/server/${brand}/i18n/${type}/${language}.json`,
    `assets/server/${brand}/i18n/${type}/${locale}.json`
  ]

  const translationPromises = translationPaths.map((path) => useStorage().getItem<Translations[T]>(path))
  const potentialTranslations = await Promise.all(translationPromises)
  const translations = potentialTranslations.filter(translationExists)

  return mergeDeep(...translations) || {}
}
