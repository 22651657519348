<template>
  <component :is="tag" data-test-id="base-popover" @mouseleave="onMouseleave">
    <slot v-bind="{ opened, side, toggle, open, close }" />
  </component>
</template>

<script lang="ts" setup>
import type { Placement, Side } from '@floating-ui/vue'
import type { OffsetOptions, ShiftOptions, Strategy } from '@floating-ui/core'
import { PopoverContextKey } from './context'
import type { DelayConfig } from '#types/components/base/popover'

const props = withDefaults(defineProps<{
  /**
   * The element or component the Popover should render as.
   */
  tag?: string
  /**
   * The default placement of the popover. Can be `top`, `bottom`, `left`, `right`.
   */
  placement?: Placement
  /**
   * If popover should change the placement of the floating element to the opposite
   * on scroll/resize
   */
  flip?: boolean
  /**
   * Show/Hide delay, or object in ms.
   */
  delay?: number | DelayConfig
  /**
   * The distance (gutter or margin) between the floating element and the reference element.
   * More details: https://floating-ui.com/docs/tutorial#offset-middleware
   */
  offset?: OffsetOptions
  /**
   * Match reference width
   * More details: https://floating-ui.com/docs/size#match-reference-width
   */
  matchReferenceWidth?: boolean
  /**
   * Moves the floating element along the specified axes in order to keep it in view.
   * More details: https://floating-ui.com/docs/tutorial#shift-middleware
   */
  shift?: ShiftOptions
  /**
   * Depends on the placement that gets chosen. For instance,
   * if the placement is 'bottom', then we want the arrow to be positioned
   * 4px outside of the top of the tooltip (so we use a negative number).
   * More details: https://floating-ui.com/docs/tutorial#arrow-middleware
   */
  arrowShift?: number
  /**
   * Where the Popover should be mounted to. By default it will be mounted to the body.
   */
  teleport?: boolean | string | HTMLElement
  /**
   * Hide Popover if mouse leaves wrapper element.
   */
  closeOnLeave?: boolean
  /**
   * The type of CSS position property to use. Two strings are available: "absolute", "fixed".
   */
  strategy?: Strategy
  /**
   * Whether the Popover should be unmounted based on the open/closed state.
   */
  destroy?: boolean
}>(), {
  tag: 'div',
  placement: 'bottom',
  arrowShift: 0,
  teleport: false,
  destroy: true
})

defineSlots<{
  default: (props: {
    opened: boolean
    side: Side
    toggle: typeof toggle
    open: typeof open
    close: typeof close
  }) => any
}>()

const opened = defineModel({ default: false })
const initiator = ref()
const floating = ref()
const side = ref(props.placement.split('-')[0] as Side)
const { show, hide } = isObject(props.delay) ? props.delay : { show: props.delay, hide: props.delay }
let timeout

const handler = (cb: (e?: Event) => void, delay?: number) => (e?: Event) => {
  clearTimeout(timeout)
  if (!delay) return cb(e)
  timeout = setTimeout(() => cb(e), delay)
}

const forceOpen = (e) => {
  initiator.value = e?.target
  opened.value = true
}
const open = handler(forceOpen, show)

const forceClose = () => {
  opened.value = false
}

const close = handler(forceClose, hide)

const toggle = (e: Event) => (opened.value ? forceClose() : forceOpen(e))

provide(PopoverContextKey, {
  opened,
  initiator,
  floating,
  side,
  forceClose,
  ...toRefs(props)
})

const onMouseleave = (e) => {
  if (!props.closeOnLeave || e.toElement === floating.value) return
  if (containsChildElement(floating.value, e.toElement)) return
  forceClose()
}

defineExpose({
  open,
  close,
  opened
})
</script>
