import type { RecursivePartial } from '#types/utils'
import type { PlpConfig } from '#types/config/pages/plp'

export default {
  defaultSort: 'bestMatches',
  sorts: {
    NORA: [
      'bestMatches',
      'whatsNew',
      'priceLowToHigh',
      'priceHighToLow'
    ],
    EMEA: [
      'bestMatches',
      'whatsNew',
      'priceLowToHigh',
      'priceHighToLow'
    ]
  },
  filterAndSort: {
    size: 'xs',
    variant: 'tertiary'
  },
  filterIconSize: 'sm',
  imageSize: 400,
  productsPerPage: 48,
  sideFilterOpenFacets: 2
} satisfies RecursivePartial<PlpConfig>
