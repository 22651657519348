import type { RecursivePartial } from '#types/utils'
import type { QuickshopConfig } from '#types/config/components/dialog/quickshop'

export default {
  brandClasses: {
    label: 'fw-bold',
    stickyFooter: 'f-col gap-y-4',
    wrapper: 'lg:(grid gap-4)'
  },
  brandStyles: {
    wrapper: {
      'grid-cols': {
        lg: 'minmax(0, 1fr) minmax(0, 0.85fr)'
      }
    }
  },
  carousel: {
    brandClasses: {
      container: 'gap-4'
    },
    pagination: true
  },
  viewDetailsVariant: 'tertiary'
} satisfies RecursivePartial<QuickshopConfig>
