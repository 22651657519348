import type { VfConfig } from '#types/config/components/vf'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    iconWrapper: 'lg:gap-4',
    logo: 'h-10',
    utilityNavigation: 'gap-4'
  },
  cartIcon: 'bag',
  cartIconSize: 'lg',
  excludeStickyRoute: ['c-slug', 'search'],
  isStickyPromoBarEnabled: false,
  showOnScrollUp: false
} satisfies RecursivePartial<VfConfig['header']>
