import type { ProductConfig } from '#types/config/components/product'

export default {
  bulletin: {
    brandClasses: {
      list: 'grid list-disc gap-4 text-sm c-grey-20 cols-2'
    }
  },
  sizeAndFit: {
    showHeader: true,
    showIcon: true
  }
} satisfies ProductConfig['details']
