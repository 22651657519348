import type { CMSConfig } from '#types/config/components/cms'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    column: 'space-y-4',
    columns: 'px-2 mt-8',
    link: 'py-4 mx-3',
    title: 'subtitle-5',
    visualSelectors: 'gap-10',
  },
  // Timberland uses problematic font that aligns differently with other DOM elements on Win vs MacOS
  // this offset introduce a workaround for this issue
  // ideally we should use a different font, but replacement may not be possible at this stage
  fontOffset: '.15rem'
} satisfies RecursivePartial<CMSConfig['megaMenuL1Desktop']>
