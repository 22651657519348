import type { SignUpConfig } from '#types/config/components/form/signUp'

export default {
  fieldsOrder: [
    'firstName',
    'lastName',
    'birthDate',
    'phone',
    'gender',
    'email',
    'password'
  ],
  isSmsOptInRequired: true,
  isBirthDateRequired: true,
  isLoyaltyNewsletterAllowed: true
} satisfies Partial<SignUpConfig>
