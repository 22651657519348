import type { ProductConfig } from '#types/config/components/product'

export default {
  eagerLoadedCards: 12,
  filtersPanelPosition: 'left',
  grid: {
    cols: {
      sm: 2,
      md: 3,
      lg: 4
    },
    gaps: {
      x: { sm: 'sm' },
      y: { sm: 'md', md: 'lg', lg: 'xl' }
    }
  },
  productsPerPage: 48
} satisfies ProductConfig['list']
