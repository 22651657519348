import type { RouteLocationNormalized, RouteLocationPathRaw, RouteLocationRaw, RouteRecordRaw, Router } from 'vue-router'
import type { Translations } from '#brand/types/i18n'
import type { I18NBrandConfig, I18NConfig } from '#types/i18n'
import type { LocaleCode } from '#types/locale'

export const useI18n = () => useState<Translations>('i18n')

const useDomainConfig = (config: I18NBrandConfig): I18NConfig => {
  const { disableDomainRestriction } = useRuntimeConfig().public

  if (config.domains && !disableDomainRestriction) {
    const host = useHost()
    const noPortHost = host?.split(':')[0]
    const configHost = Object.keys(config.domains).find((domain) => noPortHost?.endsWith(domain))

    if (configHost) {
      return {
        ...config,
        ...config.domains[configHost]
      }
    }
  }

  return config
}

export const useI18nConfig = () => {
  const config = useRuntimeConfig().public.i18n

  return reactiveComputed(() => {
    const domainConfig = useDomainConfig(config)

    return {
      locales: domainConfig.locales,
      defaultLocale: domainConfig.defaultLocale,
      urlPrefixStrategy: domainConfig.urlPrefixStrategy || 'prefix'
    }
  })
}

export const useLocale = (): LocaleCode => {
  const locale = useState<LocaleCode>('i18n.locale')
  const { locales, defaultLocale } = useI18nConfig()

  // if locale is not set or current locale is not in the included locale list, reset it
  if (!locale.value || !locales.includes(locale.value)) {
    const { ssrContext } = useNuxtApp()
    const localeCookie = useCookie<LocaleCode>('locale')

    // get locale from current URL
    // for SSR use ssrContext?.url
    // for CSR use window.location.pathname
    const currentUrl = import.meta.server ? ssrContext?.url : window.location.pathname

    locale.value = locales.find((l) => currentUrl?.startsWith(slug(l))) || localeCookie.value || defaultLocale
    localeCookie.value = locale.value
  }

  return locale.value
}

/**
 *
 * @returns user's language code, by default it will use default locale language code
 * @example 'EN'
 */
export const useLanguageCode = () => {
  return getLanguageCode(useLocale())
}

/**
 *
 * @returns user's country code, by default it will use default locale country code
 * @example 'US'
 */
export const useCountryCode = () => {
  return getCountryCode(useLocale())
}

/**
 *
 * @returns user's currency code, by default it will use default locale currency code
 * @example 'USD'
 */
export const useCurrencyCode = (locale?: LocaleCode) => {
  const _locale = locale || useLocale()
  const { currency } = useRuntimeConfig().public

  return currency[_locale] || ''
}

/**
 *
 * @returns Site Name based on brand and user's country code.
 * @example 'TIMBERLAND-US'
 */
export const useSiteName = () => {
  const { brand } = useRuntimeConfig().public
  const countryCode = useCountryCode()

  if (brand && countryCode) return `${brand}-${countryCode}`.toUpperCase()

  throw (new Error('Could not get Site Name'))
}

/**
 *
 * @returns Site ID
 * @description retrieved from LD runtime config or brand's prefix and user's country code.
 * @example 'TBL-US'
 */
export const useSiteId = () => {
  const { brandPrefix } = useAppConfig().api

  const countryCode = useCountryCode()

  return getSiteId(brandPrefix, countryCode)
}

/**
 * @returns Region name
 * @description retrieved by mapping country code
 * @example 'EMEA' | 'NORA'
 */
export const useRegion = () => getRegion(useCountryCode())

export const useLocalisedRouteRecord
  = (route: RouteRecordRaw, locale: LocaleCode, urls: LocaleContent): RouteRecordRaw => {
    const { defaultLocale, urlPrefixStrategy } = useI18nConfig()

    const localisedPath = (urls[route.path] || route.path) as string
    let localeSlug = slug(locale)

    if (locale === defaultLocale && urlPrefixStrategy === 'prefix_except_default')
      localeSlug = ''

    return {
      ...route,
      name: localiseRouteName(route, locale),
      path: `${localeSlug}${encodeURI(localisedPath)}`,
      meta: {
        ...(route.meta || {}),
        locale
      },
      children: route.children?.map((child) => ({
        ...child,
        name: localiseRouteName(child, locale),
        path: (
          urls[`${route.path}/${child.path}`]
            ? localeSlug + urls[`${route.path}/${child.path}`]
            : child.path
        ) as string
      })) || []
    }
  }

export const useLocalisedPath = (path: string): string => useNuxtApp().$urls[path] || path

export const useLocalisedRoute = (route: string | RouteLocationPathRaw | RouteLocationRaw) => {
  const locale = useLocale()
  const { defaultLocale, urlPrefixStrategy } = useI18nConfig()

  if (
    urlPrefixStrategy === 'no_prefix'
    || (urlPrefixStrategy === 'prefix_except_default' && locale === defaultLocale)
  )
    return route

  if (typeof route === 'string' && isInternal(route) && !isLocalised(route, locale) && !isMedia(route))
    return `${slug(locale)}${useLocalisedPath(route)}`

  if (route && typeof route === 'object' && 'path' in route) {
    return {
      ...route,
      path: route.path && useLocalisedRoute(route.path)
    }
  }

  return route
}

export const useDelocalisedRoute = (
  route: string | RouteLocationNormalized,
  router?: Router,
  _locales?: LocaleCode[]
) => {
  const path = ((route as RouteLocationNormalized).path || route) as string
  const locales = _locales || useI18nConfig().locales
  const delocalisedPath = locales.reduce((acc, l) => acc.replace(slug(l), ''), path) || '/'

  return typeof route === 'string' ? delocalisedPath : (router || useRouter()).resolve(delocalisedPath)
}

export const useFormattedLocale = () => useLocale().replace(/-(\w+)/, (_, g) => `_${g.toUpperCase()}`)
