import type { DetailsItemConfig } from '#types/config/components/order/detailsItem'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    itemSummaryHeading: 'subtitle-7 b-grey-50 <md:(b-t pt-8) md:(b-b pb-2)',
  },
  overrideNarvar: {
    requiredLocales: ['en-ch', 'de-ch', 'fr-ch', 'it-ch', 'fr-lu', 'nl-lu', 'de-lu'],
    urls: {
      'de-ch': 'https://www.timberland.ch/de-ch/kundenservice/ruckversand',
      'de-lu': 'https://www.timberland.lu/fr-lu/aide-et-contact/retours',
      'en-ch': 'https://www.timberland.ch/en-ch/customer-care/returns',
      'fr-ch': 'https://www.timberland.ch/fr-ch/aide-et-contact/retours',
      'fr-lu': 'https://www.timberland.lu/fr-lu/aide-et-contact/retours',
      'it-ch': 'https://www.timberland.ch/it-ch/it-it/assistenza-clienti/resi',
      'nl-lu': 'https://www.timberland.lu/fr-lu/aide-et-contact/retours',
    }
  }
} satisfies RecursivePartial<DetailsItemConfig>
