<script lang="ts">
import { Teleport, Transition, vShow } from 'vue'
import type { Side } from '@floating-ui/vue'
import {
  arrow as arrowMiddleware,
  autoUpdate,
  flip as flipMiddleware,
  offset as offsetMiddleware,
  shift as shiftMiddleware,
  size as sizeMiddleware,
  useFloating
} from '@floating-ui/vue'
import { PopoverContentContextKey, PopoverContextKey } from './context'

export default defineComponent({
  inheritAttrs: false,
  props: {
    /**
     * The element or component the PopoverContent should render as.
     */
    tag: {
      type: String,
      default: 'div'
    },
    /**
     * The same as "name" prop in the Transition component.
     */
    transition: String,
    /**
     * The same as "enterClass" prop in the Transition component.
     */
    enterFromClass: String,
    /**
     * The same as "enterActiveClass" prop in the Transition component.
     */
    enterActiveClass: String,
    /**
     * The same as "enterToClass" prop in the Transition component.
     */
    enterToClass: String,
    /**
     * The same as "leaveClass" prop in the Transition component.
     */
    leaveFromClass: String,
    /**
     * The same as "leaveActiveClass" prop in the Transition component.
     */
    leaveActiveClass: String,
    /**
     * The same as "leaveToClass" prop in the Transition component.
     */
    leaveToClass: String
  },
  setup(props, { slots, attrs }) {
    const context = inject(PopoverContextKey)!

    const {
      opened,
      flip,
      teleport,
      initiator,
      floating,
      destroy,
      closeOnLeave,
      forceClose,
      placement: defaultPlacement,
      matchReferenceWidth,
      offset,
      shift,
      arrowShift,
      side,
      strategy: defaultStrategy
    } = context
    const arrow = ref()
    let alreadyOpened = false

    // Implementation based on Floating UI docs
    // https://floating-ui.com/docs/vue
    const { x, y, placement, strategy, middlewareData } = useFloating(initiator, floating, {
      strategy: defaultStrategy,
      placement: defaultPlacement,
      middleware: computed(() => [
        ...(flip.value ? [flipMiddleware()] : []),
        ...(matchReferenceWidth.value
          ? [sizeMiddleware({
              apply({ elements, rects }) {
                Object.assign(elements.floating.style, {
                  width: `${rects.reference.width}px`
                })
              }
            })]
          : []),
        shiftMiddleware(shift?.value),
        offsetMiddleware(offset?.value),
        arrowMiddleware({ element: arrow })
      ]),
      whileElementsMounted: (_reference, _floating, update) =>
        autoUpdate(_reference, _floating, () => {
          // update popover position only if it is visible
          if (opened.value) update()
        })
    })

    watch(placement, () => side.value = placement.value.split('-')[0] as Side)

    const clickOutsideCleanup = onClickOutside(floating, () => {
      if (opened.value) forceClose()
    }, { ignore: [initiator] })

    onUnmounted(() => clickOutsideCleanup?.())

    provide(PopoverContentContextKey, {
      arrow,
      placement,
      side,
      arrowShift,
      middlewareData
    })

    return () => {
      const { tag, transition, ...transitionProps } = props
      const node = h(
        tag,
        {
          ref: floating,
          ...attrs,
          style: [{
            position: strategy.value,
            top: `${y.value || 0}px`,
            left: `${x.value || 0}px`
          }, attrs.style],
          ...(closeOnLeave.value && {
            onMouseleave(e: { toElement: HTMLElement }) {
              if (e.toElement === initiator.value) return
              if (containsChildElement(initiator.value, e.toElement)) return
              forceClose()
            }
          })
        },
        slots.default?.()
      )

      const withTransition = h(Transition, {
        name: transition,
        ...transitionProps
      }, () => {
        if (opened.value) alreadyOpened = true

        return (destroy.value || !alreadyOpened)
          ? (opened.value && node)
          : withDirectives(node, [[vShow, opened.value]])
      })

      return teleport.value
        ? h(Teleport, { to: teleport.value === true ? 'body' : teleport.value }, withTransition)
        : withTransition
    }
  }
})
</script>
