import type { RecursivePartial } from '#types/utils'
import type { PdpConfig } from '#types/config/pages/pdp'

export default {
  benefitRatingsMaxScale: 3,
  brandClasses: {
    productHeadingName: 'title-2',
    reviewsHeading: 'text-center',
    reviewsSearchSubmit: 'rounded-r-sm bg-brand-2 c-white p-1',
    shopTheLookButton: 'uppercase fw-bold'
  },
  details: {
    ctaSize: {
      sm: 'sm',
      lg: 'md'
    },
    ctaVariant: 'tertiary',
    favoriteCta: {
      iconSize: 'md',
      iconOnly: false,
      variantSelected: 'tertiary-inverse'
    },
    findInStoreCta: {
      showIcon: true
    }
  },
  gallery: {
    maxZoom: 5,
    loop: true,
    presets: {
      src: { width: 650, height: 650 },
      srcLarge: { width: 850, height: 850 },
      srcZoom: { width: 1300, height: 1300 },
      srcThumbnail: { width: 155, height: 155 }
    },
    thumbnails: false
  },
  reviews: {
    iconSize: 'lg'
  },
  sizeChartWrapper: 'modal',
  swatches: {
    collapseThreshold: 24,
    size: { sm: 'sm', lg: 'md' },
    variant: 'round'
  },
  upsells: {
    showButton: false,
    showCheckbox: true,
    showDetails: true
  }
} satisfies RecursivePartial<PdpConfig>
