import type { VfConfig } from '#types/config/components/vf'

export default {
  newsletter: {
    checkboxSize: 'sm'
  },
  showCustomizationContent: true,
  showLoyaltyContent: true,
  showSocialMediaLinks: true,
  showStoreLocator: true
} satisfies VfConfig['footer']
