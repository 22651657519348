import type { PagesConfig } from '#types/config/pages'
import type { RecursivePartial } from '#types/utils'

export default {
  exclusionList: [
    'img',
    'fonts',
    'nuance',
    'livechat',
    'worker.js',
    '_nuxt'
  ],
  padding: {
    default: 'pt-6 md:pt-8 lg:pt-10'
  }
} satisfies RecursivePartial<PagesConfig['cms']>
