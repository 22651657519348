import type { VfConfig } from '#types/config/components/vf'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    selected: 'c-grey-10 bg-brand-1 b-brand-1',
    box: 'rounded-sm text-xs px-1',
    rectangle: 'px-3.5'
  }
} satisfies RecursivePartial<VfConfig['sizePicker']>
