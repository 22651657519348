<template>
  <custom-nuxt-link v-bind="{ ...props, to, target, external }" data-test-id="base-link">
    <slot />
  </custom-nuxt-link>
</template>

<script lang="ts" setup>
import type { RouteLocationRaw } from 'vue-router'

const props = withDefaults(defineProps<{
  to?: RouteLocationRaw
  target?: '_blank' | '_self' | null
  prefetch?: boolean
  ariaCurrentValue?: 'location' | 'false' | 'true' | 'page' | 'time' | 'date' | 'step' | undefined
}>(), {
  prefetch: false,
  // type-casting is used due to router-link TS bug that doesn't allow null value to be passed
  ariaCurrentValue: null as unknown as undefined
})

const to = computed(() => useLocalisedRoute(props.to!))
const external = computed(() => !!(typeof props.to === 'string' && props.to.match(/^https?:\/\//)))
const target = computed(() => props.target || (external.value ? '_blank' : undefined))
const CustomNuxtLink = defineNuxtLink({ trailingSlash: external.value ? undefined : 'remove' })
</script>
