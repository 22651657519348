import type { RecursivePartial } from '#types/utils'
import type { ColorPickerConfig } from '#types/config/components/vf/colorPicker'

export default {
  thumbnail: {
    rounded: {
      width: 24,
      height: 24,
    },
    rectangle: {
      width: 64,
      height: 64,
    },
    format: 'png'
  },
  rectangle: {
    width: {
      sm: '3rem',
      md: '4.5rem',
      lg: '4.5rem'
    },
    height: {
      sm: '3rem',
      md: '4.5rem',
      lg: '4.5rem'
    },
    swatchInsetUnavailable: {
      sm: '0.4375rem',
      md: '0.5rem',
      lg: '0.5rem'
    },
    swatchInset: {
      sm: '0.1875rem',
      md: '0.25rem',
      lg: '0.25rem'
    },
    crossInset: {
      sm: '0.1875rem',
      md: '0.25rem',
      lg: '0.25rem'
    }
  },
  round: {
    width: {
      sm: '2.5rem',
      md: '2.5rem',
      lg: '3rem'
    },
    height: {
      sm: '2.5rem',
      md: '2.5rem',
      lg: '3rem'
    },
    padding: {
      sm: '0.5rem',
      md: '0.25rem',
      lg: '0.25rem'
    },
    swatchInsetUnavailable: {
      sm: '0.3125rem',
      md: '0.375rem',
      lg: '0.375rem'
    },
    swatchInset: {
      sm: '0.1875rem',
      md: '0.25rem',
      lg: '0.25rem'
    },
    crossInset: {
      sm: '0.1875rem',
      md: '0.25rem',
      lg: '0.25rem'
    }
  }
} satisfies RecursivePartial<ColorPickerConfig>
