import type { PagesConfig } from '#types/config/pages'

export default {
  defaultSrc: 'https://hosted.meetsoci.com/timberland/index.html',
  localizedSrc: [
    {
      locales: [
        'de-at',
        'de-be',
        'de-ch',
        'de-de',
        'de-lu',
        'es-es',
        'fr-be',
        'fr-ch',
        'fr-fr',
        'fr-lu',
        'it-ch',
        'it-it',
        'nl-be',
        'nl-lu',
        'nl-nl',
        'pt-pt',
      ],
      src: 'https://hosted.where2getit.com/timberland/timberlandeu/index_{lang}.newdesign.html'
    },
    {
      locales: ['en-ca', 'fr-ca'],
      src: 'https://hosted.meetsoci.com/timberland/index.html?lang={lang}'
    },
    {
      locales: ['en-dk'],
      src: 'https://hosted.where2getit.com/timberland/timberlandeu/index_eu.html'
    },
    {
      locales: ['en-ch', 'en-gb', 'en-ie', 'en-se'],
      src: 'https://hosted.where2getit.com/timberland/timberlandeu/index_eu.0827.html'
    },
    {
      locales: ['en-gb'],
      src: 'https://hosted.where2getit.com/timberland/timberlandeu/index_eu.0827.html?country=UK'
    },
  ]
} satisfies PagesConfig['storeLocator']
