import type { RecursivePartial } from '#types/utils'
import type { CartConfig } from '#types/config/components/cart'

export default {
  brandClasses: {
    itemsSection: 'pb-0 md:pb-12',
    noItemsSection: 'mx-a py-16 md:py-20 -mt-4 lg:-mt-6 text-center title-2'
  },
  coupon: {
    brandClasses: {
      formError: 'mt-1',
      pillsWrapper: 'flex wrap mt-2 gap-2',
      pillsTitle: 'text-xs'
    },
    buttonVariant: 'secondary'
  },
  item: {
    actionMenu: {
      brandClasses: {
        button: 'px-4 py-3 gap-3',
        list: 'divide-grey-50'
      },
      iconSize: 'md'
    },
  },
  outOfStock: {
    showHeader: true,
    showPrice: true,
    showRemoveAll: true,
    showRemoveItem: true,
    showSaveForLater: true
  },
  quickshop: {
    wrapper: {
      sm: 'panel',
      md: 'panel',
      lg: 'modal'
    },
    position: 'bottom'
  },
  savedForLater: {
    savedForLaterPageSize: 6,
    moveToCartIcon: 'bag'
  },
  showItemLevelPromocode: true,
  thumbnails: {
    size: { width: 94, height: 94 }
  },
  cartItem: {
    thumbnails: {
      width: { sm: 94, lg: 128 },
      height: { sm: 94, lg: 128 }
    }
  },
  variantOrder: ['color', 'size', 'width', 'length']
} satisfies RecursivePartial<CartConfig>
