import type { ProductConfig } from '#types/config/components/product'
import type { RecursivePartial } from '#types/utils'

export default {
  addButtonSize: 'tiny',
  badgePosition: 'top',
  brandClasses: {
    sectionGallery: 'mb-1 relative',
    swatchCount: 'mb-4',
    eyebrow: 'mt-1',
    title: 'text-sm mt-1',
    badge: 'h-8',
    badgeLabel: 'c-grey-20'
  },
  maxCarouselItems: 5,
  maxGalleryItems: 3,
  mediaSize: {
    width: 340,
    height: 340
  },
  ratingSize: 'xs'
} satisfies RecursivePartial<ProductConfig['card']>
